<template>
  <div class="w-screen h-screen 
  py-10
  px-6
  
  absolute top-0 left-0
   bg-black/50 z-20 
   flex items-center justify-center"
  >
    <div class="max-w-appMaxWidth w-full bg-white rounded-md px-6 py-6">
      <img :src="props.image" class="w-full image-pixelated" />
      <slot />
      <RydButton
        class="w-full mt-4"
        @click="$emit('close')"
      >
        {{ $t('history.wash.btn_close') }}
      </RydButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import RydButton from './RydButton/RydButton.vue';

const props = defineProps({
  image: {
    type: String,
    required: true
  }
});
const emit = defineEmits(['close']);
</script>