<template>
  <TopBar 
    :title="$t('history.details.title_cost')"
    backBtnVisible
    isCustomColor
    iconColor="text-white"
    @backButtonClick="$router.push('/history')"
  />
  <LoadingOverlay v-if="isLoading" />
  <div v-if="!categoryInit" class="px-6 pt-4">
    <div class="flex justify-between items-center">
      <p class="font-medium text-left text-tertiary-text">
        {{ $t('history.details.cost.filter_category_title') }}
      </p>

      <RPButton 
        v-if="vehicles?.length > 1 && vehicleSelectedFromStore === 'ALL'"
        theme="secondary" 
        class="justify-between w-auto min-w-[80px] m-0 px-4 py-0 bg-card-bg" 
        @click="selectVehicleModalOpen = true"
      >
        <template v-if="vehicleSelectedForCreate">
          <RPCarIcon
            :avatar="vehicleSelectedForCreate.avatarImageName"
            :color="vehicleSelectedForCreate.avatarColor"
            class="w-6 mr-2 h-fit"
          />
          <span class="pr-2">{{ vehicleSelectedForCreate.nickName }}</span>
        </template>
        <template v-else>
          <RydIcon icon="history/ryd" class="mr-1" />
          <span class="pr-2">{{ $t('history.filter.select_vehicles') }}</span>
        </template>
        <RPIcon type="arrow-down" />
      </RPButton>
    </div>

    <ul class="pt-6 text-left font-bold">
      <template v-for="category in Object.keys(COST_EVENTS_TEXT_MAP)">
        <li 
          v-if="category !== 'GIFT_CARD'" 
          :key="category" 
          class="mb-6" 
          
          @click="categoryInit = category"
        >
          <RPIcon :type="`history/${getCategoryIconUtils(category)}`" noFill class="mr-3" />
          {{ $t(`history.details.cost.category.${getCategoryTextUtils(category)}`) }}
        </li>
      </template>
    </ul>
  </div>
  <CostDetails 
    v-else
    :category="categoryInit"
    isCreateComponent
    :error="APIError"
    @create="createEvent"
  />
  <ModalBottom v-if="selectVehicleModalOpen" class="text-left" :customClass="['h-[30vh]', 'pb-3']" @close="$router.push('/history')">
    <div class="flex justify-between items-center">
      <p class="rp-fs-16-ln font-semibold">
        {{ $t('history.filter.select_vehicles') }}
      </p>
    </div>

    <ul class="pt-6">
      <li 
        v-for="vehicle in vehicles" 
        :key="vehicle.thingId" 
        class="mb-6 flex items-center"
        @click="onVehicleSelected(vehicle)"
      >
        <RPCarIcon :avatar="vehicle.avatarImageName" :color="vehicle.avatarColor" class="w-6 mr-2 h-auto" />
        <button class="h-fit">
          {{ vehicle.nickName }}
        </button>
      </li>
    </ul>
  </ModalBottom>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import CostDetails from './components/CostDetails.vue';
import ModalBottom from '@/components/ModalBottom.vue';
import RPCarIcon from '@/components/CarsSVGComponents/RPCarIcon.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import RydIcon from '@/components/RydIcon/RydIcon.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';

import { HistoryCreateCostPayload } from '@/store/history/types';
import { useHistoryStore } from '@/store/history/history';
import { useAuthStore } from '@/store/auth/auth';

import {
  getCategoryIconUtils,
  getCategoryTextUtils,
  COST_EVENTS_TEXT_MAP
} from '@/store/history/historyUtils';
import { ThingAvatars } from '@/api/user';

import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const categoryInit = ref(null);
const historyState = useHistoryStore();
const authState = useAuthStore();
const router = useRouter();

const isLoading = ref(false);
interface PayloadCreate {
  form: HistoryCreateCostPayload;
  images: {
    imageBuffer: ArrayBuffer;
    type: string;
  }[];
}

const selectVehicleModalOpen = ref(false);

onMounted(() => {
  if (vehicleSelectedFromStore.value === 'ALL') {
    selectVehicleModalOpen.value = true;
  } else {
    historyState.thingToCreateEvent.thingId = vehicleSelectedFromStore.value;
  }
});

const vehicles = computed(() => authState.userPreferences);
const vehicleSelectedFromStore = computed(() => historyState.thingIdToShowEvents);
const vehicleSelectedForCreate = ref(vehicles.value.length === 1 ? vehicles.value[0] : null);

const onVehicleSelected = (vehicle: ThingAvatars) => {
  selectVehicleModalOpen.value = false;
  vehicleSelectedForCreate.value = vehicle;
  historyState.thingToCreateEvent = vehicle;
};


const APIError = ref();
const createEvent = async (payload: PayloadCreate) => {
  try {
    isLoading.value = true;
    APIError.value = null;

    await historyState.createEventCost(payload.form, payload.images);
    router.push('/history');
  } catch (error: any) {
    console.log(error);
    if (error.response?.data?.message?.includes('carOdometerM')) {
      APIError.value = 'mileage';
    } else {
      router.push('/error');
    }
  } finally {
    isLoading.value = false;
  }
};
</script>