<template>
  <div class="separator-container py-4 relative overflow-hidden">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.separator-container {
  font-size: 15px;

  &:before,
  &:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 45%;
    height: 1px;
    content: '\a0';
    background-color: #000000;
  }
  &:before {
    margin-left: -50%;
  }
  &:after {
    margin-left: 5%;
  }
}
</style>
