<template>
  <div class="flex flex-col">
    <label class="fs-13 in-car:fs-19 block mb-1 font-bold" :for="props.id">{{ props.label }}</label>
    <input
      :id="props.id"
      v-model="valueModel"
      :name="props.name"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      class="
      fs-16-ls in-car:fs-30 block 
      w-full
      p-3
      border border-ryd-gray3 rounded-s
      focus:border-ryd-gray5 focus:outline-none focus:ring-1 focus:ring-ryd-gray5"
      :class="{ 'bg-transparent': props.disabled }"
      v-bind="{ ...$attrs }"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps({
  id: {
    type: String,
    default: () => '',
  },
  name: {
    type: String,
    default: () => '',
  },
  label: {
    type: [String, Number],
    default: () => '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  inputType: {
    type: String,
    default: 'text',
  }
});

const value = ref('');
const valueModel = defineModel('valueModel');
</script>