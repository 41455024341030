<template>
  <div class="w-full h-36 relative">
    <VoucherOverlay
      v-if="props.activateIsLoading || props.activateError"
      :activateIsLoading="props.activateIsLoading"
      :activateError="props.activateError"
    />
    <div v-if="voucher.activated" class="
          bg-tertiary-text text-center text-primary-btn-text font-medium
          rounded-br-lg w-24 
          absolute top-0 py-1 z-10"
    >
      {{ $t('vouchers.activated') }}
    </div>
    <img 
      :src="props.voucher.campaign.imageUrl" 
      alt="voucher image" 
      class="object-cover w-full h-full"
      :class="{ 'saturate-0': !voucher.activated }"
      @error="imageLoadError"
    />
  </div>
  <VoucherShortInfo
    :voucher="props.voucher" 
    class="px-2" 
    :isExpired="props.isExpired"
    @activate="activateVoucher"
  />
  <div class="px-4 text-left w-full">
    <p class="pt-4" v-html="props.voucher.campaign.description" />
    <hr class="
      block h-[1px]
      border-0 border-t border-solid border-tertiary-text
      opacity-50 w-full my-4 mx-auto p-0"
    >
    <RPButton 
      theme="custom" 
      class="flex items-center justify-between px-0 rounded-none" 
      @click="tncVisible = !tncVisible"
    >
      {{ $t('vouchers.tnc_accordion') }}
      <RPIcon type="accordion-arrow" noFill :class="{ 'rotate-180': tncVisible }" class="transition-all" />
    </RPButton>
  </div>

  <div v-if="tncVisible" class="px-4 text-left w-full">
    <p v-if="props.voucher.campaign.tnc.text" class="pt-4" v-html="props.voucher.campaign.tnc.text" />
    <RPButton v-if="props.voucher.campaign.tnc.url" link :href="props.voucher.campaign.tnc.url" class="py-3 mt-4">
      {{ $t('vouchers.tnc_link') }}
    </RPButton>
  </div>
</template>
<script setup lang="ts">
import VoucherOverlay from './components/VoucherOverlay.vue';
import VoucherShortInfo from './components/VoucherShortInfo.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import voucherFallbackImg from '@/assets/img/voucher-fallback.jpg';

import { CampaignParticipation } from '@/store/vouchers/types';
import { onMounted, ref, PropType } from 'vue';

const emits = defineEmits(['activate']);

const props = defineProps({
  voucher: {
    type: Object as PropType<CampaignParticipation>,
    required: true
  },
  activateIsLoading: {
    type: Boolean,
    required: true
  },
  activateError: {
    type: Boolean,
    required: true
  },
  isExpired: {
    type: Boolean,
    default: false
  }
});
const activateVoucher = () => {
  emits('activate', props.voucher.campaign.id);
};
onMounted(() => window.scrollTo(0, 0));
const tncVisible = ref(false);
const imageLoadError = (e: Event) => {
  const target = e.target as HTMLImageElement;
  target.src = voucherFallbackImg;
};
</script>