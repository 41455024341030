import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';

import NotFound from '@/views/NotFound.vue';
import Maintenance from '@/views/Maintenance.vue';
import Error from '@/views/Error.vue';

import History from '@/views/History/History.vue';
import HistoryDetails from '@/views/History/HistoryDetails.vue';
import HistoryCreate from '@/views/History/HistoryCreate.vue';
import MergeTrip from '@/views/History/MergeTrip.vue';
import TransactionsOverview from '@/views/History/TransactionsOverview.vue';

import VoucherList from '@/views/Vouchers/VoucherList.vue';
import VoucherCustomAdd from '@/views/Vouchers/VoucherCustomAdd.vue';

import Loyalty from '@/views/Loyalty/Loyalty.vue';
import LoyaltyAdd from '@/views/Loyalty/LoyaltyAdd.vue';
import LoyaltyRegister from '@/views/Loyalty/LoyaltyRegister.vue';

// Wash
import ProfileWash from './views/Profile/ProfileWash.vue';
import ProfileWashDetails from './views/Profile/ProfileWashDetails.vue';
// Cars
import Cars from './views/Cars/Cars.vue';
import CarDetails from './views/Cars/CarDetails.vue';

import Login from './views/Login/Login.vue';
import ForgottenPassword from './views/Login/ForgottenPassword.vue';

import { useAuthStore } from '@/store/auth/auth';


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/vouchers',
      component: VoucherList,
    },
    {
      path: '/vouchers/add',
      component: VoucherCustomAdd,
    },
    {
      path: '/loyalty',
      component: Loyalty,
    },
    {
      path: '/loyalty/add',
      component: LoyaltyAdd,
    },
    {
      path: '/profile/loyalty/register',
      component: LoyaltyRegister,
    },
    {
      path: '/history',
      component: History,
      meta: { requiresAuth: true, requiresAllThing: true }
    },
    {
      path: '/history/create',
      component: HistoryCreate,
      meta: { requiresAuth: true, requiresAllThing: true }
    },
    {
      path: '/history/:id',
      component: HistoryDetails,
      meta: { requiresAuth: true, requiresAllThing: true }
    },
    {
      path: '/history/:id/merge',
      component: MergeTrip,
      meta: { requiresAuth: true, requiresAllThing: true }
    },
    {
      path: '/history/transactions-overview',
      component: TransactionsOverview,
      meta: { requiresAllThing: true }
    },
    {
      path: '/profile/wash',
      component: ProfileWash,
      meta: { requiresAllThing: true }
    },
    {
      path: '/profile/wash/:id',
      component: ProfileWashDetails,
      meta: { requiresAllThing: true }
    },
    {
      path: '/cars',
      component: Cars,
      meta: { requiresAuth: true, requiresAllThing: true }
    },
    {
      path: '/cars/:id',
      component: CarDetails,
      meta: { requiresAuth: true, requiresUserId: true, requiresAllThing: true }
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/forgotten-password',
      component: ForgottenPassword
    },
    {
      path: '/tutorial',
      component: () => import('./views/Tutorial/Tutorials.vue')
    },
    {
      path: '/tutorial/:id',
      component: () => import('./views/Tutorial/Tutorial.vue')
    },
    {
      path: '/maintenance',
      component: Maintenance
    },
    {
      path: '/error',
      component: Error
    },
    {
      path: '/:pathMatch(.*)',
      component: NotFound
    }
  ]
});

import { setLanguage } from './i18n.js';

const hasQueryParams = (route: RouteLocationNormalized) => {
  return !!Object.keys(route.query).length;
};
type URLParamsType = {
  token: string;
  account: string;
  lang: string;
  userId: string;
  email: string;
  currency: string;
};
router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);

  const authState = useAuthStore();

  const params = to.query as URLParamsType;

  // Save the token, is always needed

  if (!authState.authToken || authState.authToken !== from.query.token) {
    await authState.saveToken(params.token, params.lang);
    await setLanguage(authState.lang);
  }
  // Page requires thing ID
  if (to.matched.some(record => record.meta.requiresAllThing)) {
    if (!authState.userPreferences?.length) await authState.storeUserPreferences();

    const thingFromQuery = to.query.thingId || from.query.thingId;

    if (!authState.preferredVehicle) {
      await authState.setPreferredVehicle(thingFromQuery as string);
    }
  }

  if (to.matched.some(record => record.meta.requiresUserId)) {
    if (authState.userId !== from.query.userId && !authState.userId) {
      await authState.getUserId();
    }
  }
  // Check requiresAuth
  if (to.matched.some(record => record.meta.requiresAuth)) {

    // We save the URL params into store if the auth store is empty or if there's a new token in URL
    if (!authState.authToken || authState.authToken !== from.query.token) {

      try {
        authState.isLoading = true;

        await authState.saveAuthData(params.userId, params.account, params.email, params.currency);

      } catch (error) {
        console.log('router auth error', error);
      } finally {
        authState.isLoading = false;

      }
    }
  }
  // We persist the parameters through all the pages
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    next({ path: to.path, query: from.query });
  } else {
    next();
  }
});

export default router;
