import { useAuthStore } from '@/store/auth/auth';

const getCorrectLang = () => {
  const authStore = useAuthStore();
  const convertUSinGB = authStore.lang === 'en' ? 'en-GB' : authStore.lang;

  return convertUSinGB || 'en-GB';
};

export const currencyString = (input: string | number, currency = 'EUR') => {
  const isDeciCent = CURRENCY_DECICENT_MAP[currency as keyof typeof CURRENCY_DECICENT_MAP];
  const parseCurrency = isDeciCent
    ? isDeciCent
    : currency;

  return new Intl.NumberFormat(getCorrectLang() || undefined, {
    style: 'currency',
    currency: parseCurrency
  }).format(Number(input));
};


/**
 * @name dateString
 * @desc We need to format the date accordingly to the design (WeekDay dd/mm/yyyy - HH-mm)
 * We return and object wit 3 key-values
 * @param input date object
 * @return {
 *  weekday: Mon, Tue, Wed... in locale lang
 *  date: dd/mm/yyyy
 *  time: hh:ss 24h format
 * }
 */
export function dateString(input: Date | string): { weekday: string, date: string, time: string; } {
  if (typeof input === 'string') {
    input = new Date(input);
  }
  const weekday = new Intl.DateTimeFormat(getCorrectLang(), {
    weekday: 'short',
  }).format(input);
  const date = new Intl.DateTimeFormat(getCorrectLang(), {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(input);
  const time = new Intl.DateTimeFormat(getCorrectLang(), {
    hour: 'numeric',
    hour12: false,
    minute: 'numeric',
  }).format(input);

  return {
    weekday,
    date,
    time
  };
}
export function dateNoTime(input: string) {
  const dateObject = new Date(input);
  return new Intl.DateTimeFormat(localStorage.getItem('lang') || undefined, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  }).format(dateObject);
}
export const formattedExpirationDate = (date: string) => {
  const convertedDate = dateString(date);

  return `${convertedDate.date} ${convertedDate.time}`;
};

export const CURRENCY_DECICENT_MAP = {
  euroDeciCent: 'EUR',
  chfDeciCent: 'CHF',
  plnDeciCent: 'PLN',
  dkkDeciCent: 'DKK',
  gbpDeciCent: 'GBP',
  sekDeciCent: 'SEK',
  czkDeciCent: 'CZK',
  nokDeciCent: 'NOK',
  iskDeciCent: 'ISK',
  hrkDeciCent: 'HRK',
  bamDeciCent: 'BAM'
};

export const CURRENCY_DECICENT_SYMBOL_MAP = {
  euroDeciCent: '€',
  chfDeciCent: 'CHF',
  plnDeciCent: 'PLN',
  dkkDeciCent: 'DKK',
  gbpDeciCent: '£',
  sekDeciCent: 'SEK',
  czkDeciCent: 'CZK',
  nokDeciCent: 'NOK',
  iskDeciCent: 'ISK',
  hrkDeciCent: 'HRK',
};

/**
 * @name convertUTCDateToLocalDate
 * @description This function is for  insert the right format into the HTML element <input type=datetiame-local>
 * This is what we got from API 2023-04-09T09:34:24.486Z
 * This is what the element needs 2023-04-09T11:34
 * We need to add the timezone to the hours of the string
 * Then remove the timezone from the string generated with toISOString()
 * @param {String} date The date in the string format
 */
export const convertUTCDateToLocalDate = (date: string) => {
  const newDate = (new Date(date));
  const timestampOffset = new Date(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000);
  const offset = newDate.getTimezoneOffset() / 60;
  const hours = newDate.getHours();

  timestampOffset.setHours(hours - offset);
  return timestampOffset.toISOString().slice(0, 19);
};
export const convertUTCDateToLocalDateNoTime = (date: string) => {
  const newDate = (new Date(date));

  const timestampOffset = new Date(newDate.getTime());

  return timestampOffset.toISOString().substring(0, 10);
};
// currency formatting
export const CURRENCY_ISO_SYMBOL_MAP = {
  'EUR': '€',
  'CHF': 'CHF',
  'PLN': 'PLN',
  'DKK': 'DKK',
  'GBP': '£',
  'SEK': 'SEK',
  'CZK': 'CZK',
  'NOK': 'NOK',
  'ISK': 'ISK',
  'HRK': 'HRK',
  'BAM': 'BAM'
};
export const currencySymbolCustom = (currency: string) => {
  return CURRENCY_ISO_SYMBOL_MAP[currency as keyof typeof CURRENCY_ISO_SYMBOL_MAP];
};
export const currencySymbol = (currency: string) => {
  return CURRENCY_DECICENT_SYMBOL_MAP[currency as keyof typeof CURRENCY_DECICENT_SYMBOL_MAP];
};
export const localeNumber = (numb: number | string) => {
  const convertNumber = Number(numb);
  return new Intl.NumberFormat(getCorrectLang()).format(convertNumber);
};
export const localeNumberWithFractionDigits = (numb: number) => {
  return new Intl.NumberFormat(getCorrectLang(), { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(numb);
};
export const numberKeepPrecision = (value: number, precision: number) => {
  return new Intl.NumberFormat(getCorrectLang(), {
    maximumFractionDigits: precision,
    // @ts-ignore
    roundingMode: 'floor'
  }).format(value);
};
export const getFormattedPriceWithCurrencySymbol = (price: string, currency: string, precision: number | string) => {
  return new Intl.NumberFormat(localStorage.getItem('lang') || undefined, {
    style: 'currency',
    currency,
    maximumFractionDigits: Number(precision),
    /* @ts-ignore */
    roundingMode: 'floor'
  }).format(Number(price));
};
/**
 * @desc Dark/Light mode
 * Temporary function that changes the colors of the whole app
 * Remove it when everything will be in the new UI style
 * and just use dark:<utilityClass> in the App.js component
 */
export const checkAndApplyTheme = () => {
  document.getElementById('app')?.classList.add('f-roboto');

  if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    // dark
    // document.documentElement.classList.add('dark');
    // localStorage.theme = 'dark';

    // We force the light there for now
    localStorage.theme = 'light';
    document.documentElement.classList.add('light');
  } else {
    // light
    document.documentElement.classList.remove('dark');
    localStorage.theme = 'light';
    document.documentElement.classList.add('light');
  }
};
export const removeTheme = () => {
  document.documentElement.classList.remove('light');
  document.documentElement.classList.remove('dark');
  document.getElementById('app')?.classList.remove('f-roboto');
};
export const EV_UNITS: Record<string, string> = {
  KWH: 'kWh',
  COUNT: 'count',
  MIN: 'min'
};