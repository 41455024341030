import { defineStore } from 'pinia';
import { getUserIdAPI, getUser, getUserPreferences, getFeaturesAPI, loginAPI, resendPwdAPI } from '@/api/user';
import { AuthStore, UserFeatureExtended, ExtendedAxiosError } from './types';
import { useLoyaltyStore } from '@/store/loyalty/loyalty';
import { useHistoryStore } from '@/store/history/history';
import { ALL_VEHICLES_FILTER } from '@/store/history/history';

export const useAuthStore = defineStore('auth', {
  state: (): AuthStore => ({
    authToken: null,
    accountId: null,
    userId: null,
    email: null,
    lang: null,
    userCurrency: null,
    userPreferences: null,
    preferredVehicle: null,
    userFeatures: [],
    isLoading: false
  }),
  actions: {
    async saveToken(token: string, lang: string) {

      this.authToken = token;
      this.lang = lang;
    },
    async getUserId() {
      const userSimpleData = await getUserIdAPI();
      this.userId = userSimpleData[0]._id;
    },
    async saveAuthData(userId: string = null, account: string = null, email: string = null, currency: string = null) {

      // if there's no email or currency in the URL, we eed to retrieve them from /users/{:userId}
      // if the userId is missing too, we need to retrieve it from /users
      if (!email || !currency) {
        userId
          ? this.userId = userId
          : await this.getUserId();
        await this.getUserAccountEmailCurrency();
      } else {
        this.userId = userId,
          this.email = email,
          this.userCurrency = currency,
          this.accountId = account;
      }
      if (!this.userPreferences?.length) await this.storeUserPreferences();
    },

    async getUserAccountEmailCurrency() {
      try {
        const userData = await getUser(this.userId as string);
        this.accountId = userData.accounts[0].id;
        this.userCurrency = userData.localisation.currency;
        this.email = userData.email;

      } catch (error) {
        console.log('check Auth Status', error);
      } finally {
        // commit('ui/setLoading', false, { root: true });
      }
    },
    async storeUserPreferences() {
      this.isLoading = true;

      try {
        this.userPreferences = await getUserPreferences();
        if (!this.userPreferences.length) throw new Error('things empty');

      } catch (error) {
        console.log('Get preferences error', error);
      } finally {
        this.isLoading = false;
      }
    },
    async getUserFeatures(accountId: string | null = null, retry = 0) {
      const loyaltyState = useLoyaltyStore();

      try {

        const features = await getFeaturesAPI(accountId);
        this.userFeatures = features.filter(feat => feat.status === 'ENABLED');

        const loyaltyFeat: UserFeatureExtended = this.userFeatures.find(
          (feat: UserFeatureExtended) => feat.feature === 'loyaltyPrograms');

        loyaltyFeat && loyaltyState.setLoyaltyPrograms(loyaltyFeat.programs);
      } catch (error: any) {

        // if accountId in URL is wrong or absent AND retry is 0 (avoid infinite recursion)
        if (error.response?.status === 401 && retry === 0) {
          console.log(retry);

          try {
            await this.getUserId();
            await this.getUserAccountEmailCurrency();
            await this.getUserFeatures(this.accountId, 1);
          } catch (error) {
            console.log('Get user data or features error', error);
            throw error;
          }
          return;
        }

        console.log('Get user features error', error);
        throw new Error(error);
      }
    },
    async setPreferredVehicle(thingId = 'ALL') {

      const historyState = useHistoryStore();
      if (!this.userPreferences) {
        await this.storeUserPreferences();
      }

      if (!thingId) {
        this.preferredVehicle = this.userPreferences[0];
        historyState.thingIdToShowEvents = this.preferredVehicle.thingId;
        return;
      }

      const vehicleFromParam = this.userPreferences.find(item => item.thingId === thingId);

      this.preferredVehicle = vehicleFromParam
        ? vehicleFromParam
        : this.userPreferences[0];

    },
    async login(email: string, password: string) {
      try {
        const authData = await loginAPI(email, password);
        if (authData.passwordExpired) {
          throw new Error('temp_pass');
        }
        this.authToken = authData.auth_token;
      } catch (error) {
        if (
          (error as ExtendedAxiosError).response?.data.errorCode === 125
          || (error as ExtendedAxiosError).response?.data.errorCode === 127
        ) {
          throw new Error('wrong_login_details');
        } else {
          throw error;
        }
      } finally {
      }

    },
    async forgotPwd(email: string) {
      try {
        await resendPwdAPI(email);
      } finally {
      }
    },
  }
});