<template>
  <TopBar
    backBtnVisible
    :title="detailIsOpen ? $t('vouchers.title_header_details') : $t('vouchers.title_header_list')"
    iconColor="text-white"
    @backButtonClick="redirectToApp()"
  />
  <button 
    v-if="showGiftCardButton" 
    class="flex justify-between items-center text-primary-text w-full px-4 pt-6" 
    @click="emitBuyGiftCard"
  >
    <div class="flex items-center justify-center">
      <RydIcon icon="vouchers/gift" isCustomColor class="h-7 mr-4 text-white" />
      <div class="text-left">
        <strong>{{ $t('vouchers.gift_card.voucher_title') }}</strong>
        <p class="fs-12 text-tertiary-text pr-4">
          {{ $t('vouchers.gift_card.voucher_subtitle') }}
        </p>
      </div>
    </div>
    <div>
      <RydIcon icon="symbols/arrowRight" isCustomColor class="text-white" />
    </div>
  </button>
  <LoadingOverlay v-if="!componentReady" />
  <div v-else class="w-full relative">
    <div v-if="!detailIsOpen" class="px-4 flex items-center flex-col pb-14 mt-4">
      <h3 class="fs-20 font-bold self-start pb-4">
        {{ $t('vouchers.title') }}
      </h3>
      <!-- Error API get vouchers -->
      <div v-if="VOUCHER_ERROR_MAP.includes(errorAPI)">
        {{ $t('vouchers.error.load_vouchers') }}
      </div>
      <div v-else-if="!campaigns.length">
        {{ $t('vouchers.no_vouchers') }}
      </div>
      <RPCard 
        v-for="item in campaigns"
        :key="item.campaign.id"
        hasClick class="p-0 mb-4 w-full"
        @click.self.stop="openDetails(item)"
      >
        <!-- overlay loading/error -->
        <VoucherOverlay
          v-if="showOverlay(item.campaign.id)"
          :activateIsLoading="activateLoadingId.includes(item.campaign.id)"
          :activateError="activateErrorId.includes(item.campaign.id)"
          :isExpired="voucherExpired"
        />
        <div v-if="item.activated" class="
          bg-tertiary-text text-center text-primary-btn-text font-medium
          rounded-br-lg w-24 
          absolute top-0 py-1 z-10"
        >
          {{ $t('vouchers.activated') }}
        </div>
        <LazyImage :imageUrl="item.campaign.imageUrl" :isActivated="item.activated" />
        <VoucherShortInfo 
          :voucher="item" 
          :isExpired="voucherExpired"
          @activate="voucherExpired ? refreshPage() : activateCampaign(item.campaign.id)"
        />
      </RPCard>
    </div>


    <VoucherDetails 
      v-else
      :voucher="selectedVoucher"
      :activateIsLoading="activateLoadingId.includes(selectedVoucher.campaign.id) ?? false" 
      :activateError="activateErrorId.includes(selectedVoucher.campaign.id) ?? false"
      :isExpired="voucherExpired"
      @activate="voucherExpired ? refreshPage() : activateCampaign()"
    />
    <div class="px-4 fixed bottom-6 z-20 w-full  max-w-appMaxWidth left-1/2 -translate-x-1/2">
      <RPButton v-if="!detailIsOpen" href="/vouchers/add" class=" ">
        {{ $t('vouchers.add_new') }}
      </RPButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import RPCard from '@/components/RPCard/RPCard.vue';
import RydIcon from '@/components/RydIcon/RydIcon.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import VoucherOverlay from './components/VoucherOverlay.vue';
import VoucherShortInfo from './components/VoucherShortInfo.vue';
import VoucherDetails from './VoucherDetails.vue';
import LazyImage from './components/LazyImage.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';

import { VOUCHER_ERROR_MAP } from '@/store/vouchers/vouchers';
import { CampaignParticipation } from '@/store/vouchers/types';

import { useAuthStore } from '@/store/auth/auth';
import { useVouchersStore } from '@/store/vouchers/vouchers';

import { onMounted, computed, ref } from 'vue';
import { useRoute } from 'vue-router';


const authState = useAuthStore();
const vouchersState = useVouchersStore();
const route = useRoute();

const showGiftCardButton = computed(() =>
  authState.userFeatures.find(item => item.feature === 'giftCardShop')?.status === 'ENABLED'
  && route.query.giftCardEnable === 'true'
  && !detailIsOpen.value
);

const componentReady = ref(false);
const errorAPI = ref(null);
onMounted(async () => {
  try {
    await vouchersState.loadVouchers();
    await authState.getUserFeatures(route.query.account as string);

  } catch (error) {
    errorAPI.value = vouchersState.error;
  } finally {
    componentReady.value = true;
  }
});

const campaigns = computed<CampaignParticipation[]>(() => vouchersState.vouchers);

// open details page
const detailIsOpen = ref(false);
const selectedVoucher = ref(null);

const openDetails = (payload: CampaignParticipation) => {
  selectedVoucher.value = payload;
  detailIsOpen.value = true;
};

// activate a voucher
// In order to hide/display the overlay related to each voucher
// we create an Array of campaigns id
const activateLoadingId = ref([]);
const voucherExpired = ref(false);
const activateErrorId = ref([]);

const refreshPage = () => {
  location.reload();
};

const activateCampaign = async (id?: string) => {
  if (!id) id = selectedVoucher.value.campaign.id;

  activateLoadingId.value.push(id);
  voucherExpired.value = false;

  try {
    selectedVoucher.value = campaigns.value.find(item => item.campaign.id === id);
    await vouchersState.activateCampaignAction(id);
    // issue on reactivity when activate from details, need to do this again
    selectedVoucher.value = campaigns.value.find(item => item.campaign.id === id);

  } catch (e) {
    activateErrorId.value.push(id);
    console.log(e);

    if (checkExpiration(selectedVoucher.value.expirationTime)) {
      console.log('voucher expired');
      voucherExpired.value = true;
    }

    setTimeout(() => {
      const campaignIndex = activateErrorId.value.indexOf(id);
      delete activateErrorId.value[campaignIndex];

    }, 3000);
  } finally {
    const campaignIndex = activateLoadingId.value.indexOf(id);
    delete activateLoadingId.value[campaignIndex];
  }
};
const checkExpiration = (date: string) => {
  const targetDate = new Date(date);
  const today = new Date();

  return targetDate.getTime() < today.getTime();
};

const showOverlay = (id: string) => activateLoadingId.value.includes(id) || activateErrorId.value.includes(id);

const redirectToApp = () => {
  const urlCallbackObject = new URL('webViewEvent://vouchersBack');
  detailIsOpen.value ? detailIsOpen.value = false : window.location.replace(urlCallbackObject.toString());
};
const emitBuyGiftCard = () => {
  const urlCallbackObject = new URL('webViewEvent://webViewGiftCardBuy');
  window.location.replace(urlCallbackObject.toString());
};
</script>