<template>
  <div class="flex items-center justify-between px-4 py-6">
    <RPButton 
      theme="secondary" 
      class="justify-between w-auto min-w-[140px] m-0 px-4 py-0 bg-card-bg" 
      @click="isFilterEvent = true"
    >
      <div class="text-primary-accent">
        <RydIcon :icon="eventSelectedFilter.icon" class="mr-1" />

        {{ eventSelectedFilter.type === 'ALL'
          ? $t('history.details.show_all')
          : $t(`history.filter.${eventSelectedFilter.text}`) }}
      </div>
      <RPIcon type="arrow-down" />
    </RPButton>
    <RPButton 
      v-if="authState.userPreferences.length > 0"
      theme="secondary" 
      class="justify-between w-auto min-w-[80px] m-0 px-4 py-0 bg-card-bg" 
      @click="isFilterVehicle = true"
    >
      <template v-if="vehicleToShowEvents && historyState.thingIdToShowEvents !== 'ALL'">
        <RPCarIcon
          :avatar="vehicleToShowEvents.avatarImageName"
          :color="vehicleToShowEvents.avatarColor"
          class="w-6 mr-2 h-fit"
        />
        <span class="pr-2">{{ vehicleToShowEvents.nickName }}</span>
      </template>
      
      <template v-else>
        <RydIcon icon="history/ryd" class="mr-1" />
        <span class="pr-2">{{ $t('history.filter.all_vehicles') }}</span>
      </template>
      
      <RPIcon type="arrow-down" />
    </RPButton>
  </div>
  <ModalBottom v-if="isFilterEvent" class="text-left" @close="isFilterEvent = false">
    <p class="fs-16 font-semibold">
      {{ $t('history.filter.title_events') }}
    </p>
    <ul class="pt-6">
      <template v-for="filter in eventFiltersMap" :key="filter.type">
        <li 
          v-if="filter.visible" 
          class="mb-6" 
          :class="{ 'text-tertiary-text font-semibold': isFilterActive(filter.type) }"
          @click="selectFilter(filter)"
        >
          <RydIcon :icon="filter.icon" class="mr-2" />
          <button>{{ $t(`history.filter.${filter.text}`) }}</button>
        </li>
      </template>
    </ul>
  </ModalBottom>
  <ModalBottom v-if="isFilterVehicle" class="text-left" :customClass="['h-[50vh]', 'pb-3']" @close="isFilterVehicle = false">
    <div class="flex justify-between items-center">
      <p class="fs-16 font-semibold">
        {{ $t('history.filter.title_cars') }}
      </p>
    </div>

    <ul class="pt-6">
      <li
        class="mb-6 flex items-center"
        :class="{ 'text-tertiary-text': historyState.thingIdToShowEvents === 'ALL' }"
        @click="selectVehicle('ALL')"
      >
        <RydIcon icon="history/ryd" class="mr-2" />
        <button class="h-fit">
          {{ $t('history.details.show_all') }}
        </button>
      </li>
      <li 
        v-for="vehicle in vehicles" 
        :key="vehicle.thingId" 
        class="h-10 flex items-center"
        :class="{ 'text-tertiary-text': historyState.thingIdToShowEvents === vehicle.thingId }"
        @click="selectVehicle(vehicle.thingId)"
      >
        <RPCarIcon :avatar="vehicle.avatarImageName" :color="vehicle.avatarColor" class="w-6 mr-2 h-auto" />
        <button class="h-fit">
          {{ vehicle.nickName }}
        </button>
      </li>
    </ul>
    <Divider class="mt-4 mb-4" />
    <Checkbox id="showArchivedCheck" v-model="showArchived" :label="$t('history.filter.show_archived')" @update:modelValue="getArchivedCars" />
    <Divider class="mt-4 mb-4" />

    <LoadingSpinner v-if="archivedLoading && showArchived" />

    <template v-else-if="vehiclesArchived.length && showArchived">
      <p class="fs-16 font-semibold">
        {{ $t('history.filter.archived_cars') }}
      </p>
      <ul class="pt-6 text-left opacity-50">
        <li 
          v-for="vehicle in vehiclesArchived" 
          :key="vehicle.thingId" 
          class="h-10 flex items-center"
          :class="{ 'text-tertiary-text': historyState.thingIdToShowEvents === vehicle.thingId }"
          @click="selectVehicle(vehicle.thingId)"
        >
          <RPCarIcon :avatar="vehicle.avatarImageName" :color="vehicle.avatarColor" class="w-6 mr-2 h-auto" />
          <button class="h-fit">
            {{ vehicle.nickName }}
          </button>
        </li>
      </ul>
    </template>
  </ModalBottom>
</template>
<script setup lang="ts">
import ModalBottom from '@/components/ModalBottom.vue';
import RPButton from '@/components/RPButton/RPButton.vue';
import RydIcon from '@/components/RydIcon/RydIcon.vue';
import RPIcon from '@/components/RPIcon/RPIcon.vue';
import RPCarIcon from '@/components/CarsSVGComponents/RPCarIcon.vue';
import Divider from '@/components/Divider.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import Checkbox from '@/components/Checkbox.vue';

import { useHistoryStore } from '@/store/history/history';
import { useAuthStore } from '@/store/auth/auth';
import { useVehicleState } from '@/store/vehicle/vehicle';
import { ThingAvatars } from '@/api/user';
import { EventFilters } from '@/store/history/types';

import { ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';


const emit = defineEmits(['eventChange', 'carChange']);
const historyState = useHistoryStore();
const authState = useAuthStore();
const vehicleState = useVehicleState();

const route = useRoute();
const router = useRouter();

//Events filter
const isFilterEvent = ref(false);
const isFilterVehicle = ref(false);

const selectFilter = (event: EventFilters) => {
  emit('eventChange', event);
  isFilterEvent.value = false;
};

const eventFiltersMap = computed(() => [
  { type: 'ALL', productTypes: '', text: 'all', visible: true, icon: 'history/ryd' },
  { type: '', productTypes: 'GAS', text: 'payment', visible: true, icon: 'history/refuel' },
  { type: '', productTypes: 'CAR_WASH', text: 'wash', visible: true, icon: 'history/wash' },
  { type: '', productTypes: 'EV_CHARGE', text: 'charging', visible: true, icon: 'history/charging' },
  { type: '', productTypes: 'GIFT_CARD', text: 'gift_card', visible: true, icon: 'history/gift-card' },
  { type: '', triggerEventTypeAtSource: 'MANUAL', text: 'manual', visible: true, icon: 'history/manual-entries' },
]);

const eventSelectedFilter = computed(() => historyState.eventSelectedFilter);
const isFilterActive = (filterText: string) => filterText === eventSelectedFilter.value.text;

// Vehicles filter
const vehicles = computed(() => authState.userPreferences);
const vehicleToShowEvents = computed(() => vehicles.value.find(item => item.thingId === historyState.thingIdToShowEvents));

const selectVehicle = (vehicleIdSelected: string) => {
  if (vehicleIdSelected === 'ALL') {
    historyState.thingIdToShowEvents = 'ALL';
    emit('carChange', 'ALL');

    isFilterVehicle.value = false;
    router.replace({
      path: route.path,
      query: { ...route.query, thingId: 'ALL' }
    });
    return;
  }

  if (historyState.thingIdToShowEvents !== vehicleIdSelected) {

    historyState.thingIdToShowEvents = vehicleIdSelected;
    // Going back and forth between pages we need to keep the car the user selected
    // We replace the thingId param in the URL

    emit('carChange', vehicleIdSelected);
    router.replace({
      path: route.path,
      query: { ...route.query, thingId: vehicleIdSelected }
    });
  }

  isFilterVehicle.value = false;
};

const archivedLoading = ref(false);
const getArchivedCars = async () => {
  if (!vehiclesArchived.value.length && showArchived.value) {
    try {
      archivedLoading.value = true;
      await vehicleState.getArchivedCars();
    } catch (error) {
      console.log(error);
    } finally {
      archivedLoading.value = false;
    }
  }
};

const showArchived = defineModel('showArchived', { type: Boolean, default: false });
const vehiclesArchived = computed(() => vehicleState.archivedCars);
</script>